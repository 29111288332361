<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- お客様の声 -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>お客様の声</heading></h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <router-link
                        class="text-decoration-none"
                        :to="{ name: 'Testimonial1' }"
                    >
                        <v-img :src="imageGuest1">
                            <div
                                :class="
                                    smAndUp
                                        ? 'inner-text-wrapper'
                                        : 'inner-text-wrapper-sm'
                                "
                            >
                                <div
                                    class="white--text font-weight-bold inner-text"
                                    :class="
                                        smAndUp
                                            ? 'text-body-1'
                                            : 'text-subtitle-2'
                                    "
                                >
                                    顔が小さくなって、小さな服も着られるようになりました！
                                </div>
                            </div>
                            <div :class="smAndUp ? 'layer' : 'layer-sm'"></div>
                        </v-img>
                    </router-link>

                    <v-col align="right">
                        <div
                            :class="
                                smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'
                            "
                        >
                            20代女性 A.Yさん
                        </div>
                    </v-col>
                </v-col>
                <v-col cols="12" md="6">
                    <router-link
                        class="text-decoration-none"
                        :to="{ name: 'Testimonial2' }"
                    >
                        <v-img :src="imageGuest2">
                            <div
                                :class="
                                    smAndUp
                                        ? 'inner-text-wrapper'
                                        : 'inner-text-wrapper-sm'
                                "
                            >
                                <div
                                    class="white--text font-weight-bold inner-text"
                                    :class="
                                        smAndUp
                                            ? 'text-body-1'
                                            : 'text-subtitle-2'
                                    "
                                >
                                    着てみたい服やお出かけしたい場所がたくさん増えました！
                                </div>
                            </div>
                            <div :class="smAndUp ? 'layer' : 'layer-sm'"></div>
                        </v-img>
                    </router-link>

                    <v-col align="right">
                        <div
                            :class="
                                smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'
                            "
                        >
                            30代女性 M.Kさん A.Yさん
                        </div>
                    </v-col>
                </v-col>
                <v-col cols="12" md="6">
                    <router-link
                        class="text-decoration-none"
                        :to="{ name: 'Testimonial3' }"
                    >
                        <v-img :src="imageGuest3">
                            <div
                                :class="
                                    smAndUp
                                        ? 'inner-text-wrapper'
                                        : 'inner-text-wrapper-sm'
                                "
                            >
                                <div
                                    class="white--text font-weight-bold inner-text"
                                    :class="
                                        smAndUp
                                            ? 'text-body-1'
                                            : 'text-subtitle-2'
                                    "
                                >
                                    40代の女性には絶対にオススメしたいです！
                                </div>
                            </div>
                            <div :class="smAndUp ? 'layer' : 'layer-sm'"></div>
                        </v-img>
                    </router-link>
                    <v-col align="right">
                        <div
                            :class="
                                smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'
                            "
                        >
                            40代女性 A.Mさん
                        </div>
                    </v-col>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Faq">よくあるご質問</link-tag>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'お客様の声',
                    disabled: true,
                    href: 'testimonial',
                },
            ]
        },

        imageGuest1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial/4B7A8691.png'
                : 'static/sp_testimonial/testimonial_img01.png'
        },
        imageGuest2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial/4B7A8859.png'
                : 'static/sp_testimonial/testimonial_img02.png'
        },
        imageGuest3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial/4B7A9028.png'
                : 'static/sp_testimonial/testimonial_img03.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.layer {
    display: block;
    background-color: #666666;
    position: absolute;
    min-width: 100%;
    height: 20%;
    top: 80%;
    left: 0%;
    opacity: 0.5;
    z-index: 8;
}
.layer-sm {
    display: block;
    background-color: #666666;
    position: absolute;
    min-width: 100%;
    height: 30%;
    top: 70%;
    left: 0%;
    opacity: 0.5;
    z-index: 8;
}
.inner-text-wrapper {
    position: absolute;
    display: table;
    top: 85%;
    left: 5%;
    opacity: 0.9;
    z-index: 9;

    .inner-text {
        display: table-cell;
        vertical-align: middle;
        color: white;
    }
}
.inner-text-wrapper-sm {
    position: absolute;
    display: table;
    top: 75%;
    left: 3%;
    opacity: 0.9;
    z-index: 9;

    .inner-text {
        display: table-cell;
        vertical-align: middle;
        color: white;
    }
}
</style>
