var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.smAndUp)?_c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadCrumbs,"divider":">"}})],1)],1)],1):_vm._e(),_c('v-container',[_c('v-row',{class:_vm.smAndUp ? 'mb-15' : null,attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","align":"center"}},[_c('h1',[_c('heading',[_vm._v("お客様の声")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Testimonial1' }}},[_c('v-img',{attrs:{"src":_vm.imageGuest1}},[_c('div',{class:_vm.smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"white--text font-weight-bold inner-text",class:_vm.smAndUp
                                        ? 'text-body-1'
                                        : 'text-subtitle-2'},[_vm._v(" 顔が小さくなって、小さな服も着られるようになりました！ ")])]),_c('div',{class:_vm.smAndUp ? 'layer' : 'layer-sm'})])],1),_c('v-col',{attrs:{"align":"right"}},[_c('div',{class:_vm.smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'},[_vm._v(" 20代女性 A.Yさん ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Testimonial2' }}},[_c('v-img',{attrs:{"src":_vm.imageGuest2}},[_c('div',{class:_vm.smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"white--text font-weight-bold inner-text",class:_vm.smAndUp
                                        ? 'text-body-1'
                                        : 'text-subtitle-2'},[_vm._v(" 着てみたい服やお出かけしたい場所がたくさん増えました！ ")])]),_c('div',{class:_vm.smAndUp ? 'layer' : 'layer-sm'})])],1),_c('v-col',{attrs:{"align":"right"}},[_c('div',{class:_vm.smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'},[_vm._v(" 30代女性 M.Kさん A.Yさん ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Testimonial3' }}},[_c('v-img',{attrs:{"src":_vm.imageGuest3}},[_c('div',{class:_vm.smAndUp
                                    ? 'inner-text-wrapper'
                                    : 'inner-text-wrapper-sm'},[_c('div',{staticClass:"white--text font-weight-bold inner-text",class:_vm.smAndUp
                                        ? 'text-body-1'
                                        : 'text-subtitle-2'},[_vm._v(" 40代の女性には絶対にオススメしたいです！ ")])]),_c('div',{class:_vm.smAndUp ? 'layer' : 'layer-sm'})])],1),_c('v-col',{attrs:{"align":"right"}},[_c('div',{class:_vm.smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'},[_vm._v(" 40代女性 A.Mさん ")])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8"}},[_c('link-tag',{attrs:{"compName":"Faq"}},[_vm._v("よくあるご質問")])],1)],1)],1),_c('footer-contents')],1)}
var staticRenderFns = []

export { render, staticRenderFns }